// See https://github.com/mui-org/material-ui/tree/master/examples/gatsby
import { SheetsRegistry } from 'jss';
import {
  createMuiTheme,
  createGenerateClassName,
} from '@material-ui/core/styles';

import theme from '../mui_theme';

// A theme with custom primary and secondary color.
// It's optional.

/*
  Source: https://material.io/inline-tools/color/

  From #1b70bb (p-main):
  Complementary: #bb661b  (orangey)
  Analogous:     #1bbbb6  (Tealish)
  #1b20bb  (Darkblue-purplelish)
  Triadic:       #661bbb  (Purpleish)
  #bb1b70  (Pinkish)

  From #3B9DDB (p-light):
  Complementary: #db783b  (orangey)
  Analogous:     #3bdbc8  (Tealish)
  #3b4edb  (Darkblue-purplelish)
  Triadic:       #783bdb  (Purpleish)
  #db3b9e  (Pinkish)
*/

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
