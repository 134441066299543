import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField, Grid, Button } from '@material-ui/core';
import classNames from 'classnames';
import Formsy from 'formsy-react';

import FormsyInputField from './FormsyInputField';
import backgroundImg from '../images/backgrounds/coffee.jpg';
import { contactRequest } from './contactRequest';
import colors from '../mui_theme/colors';


const styles = theme => ({
  root: {
    height: '100%',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      borderRadius: '0 0 0',
    },
    [theme.breakpoints.up('md')]: {
      height: 820,
      borderRadius: '0 0 25px',
    },
  },
  img: {
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 400,
      maxWidth: '100%',
      height: 'auto',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '100%',
      minHeight: '100%',
      maxWidth: 'none',
      height: '100%',
    },
  },
  form: {
    backgroundColor: colors.white,
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: 104,
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  formContent: {
    width: '100%',
  },
  button: {
    height: 60,
    textTransform: 'uppercase',
    letterSpacing: 3,
  },
  buttonContainer: {
    marginTop: 24,
  },
  progress: {
    marginLeft: 8,
  },
});

class ContactCTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      error: false,
      loading: false,
      canSubmit: false,
    };
  }

    form = React.createRef();

    disableButton = () => {
      this.setState({ canSubmit: false, error: null });
    };

    enableButton = () => {
      this.setState({ canSubmit: true });
    };

  onFormSubmit = model => {
    if (!this.state.sent) {
      const request = {
        ...model,
        subject: 'Thank you for your interest in being a pilot tester',
        extra: { source: 'Marketing site - Sign-up/Apply Today' },
        template: 'SIGN_UP',
      };

      this.setState({ loading: true }, () => {
        return contactRequest(request)
        .then(
          () => {
            this.setState({ sent: true, loading: false, error: null });
          },
        ).catch(error => {
          console.debug(error);
          // We don't want to show user an error message
          // And temporary hack for 502 Bad Gateway response
          this.setState({
            error: 'An error occured. Please try again later.',
            sent: true,
            loading: false
          })
        });
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { loading, sent, error, canSubmit } = this.state;

    return (
      <Grid container className="mt-12">
        <a href="#contact" name="contact" className={'absolute -mt-12'} />
        <Grid item xs={12} md={6} className={classes.imgContainer}>
          <img src={backgroundImg} className={classes.img} />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.form}>
            <div className={classes.formContent}>
              <p className="typography-header mb-8">Take Charge Today</p>
              <h1
                className={classNames('typography-title mb-8', classes.title)}
              >
                Apply to participate in a pilot testing program.
              </h1>
              <p className="typography-p mb-8">
                We’ll contact you within 24 hours to help you take the first steps to control your fertility health.
              </p>

              <Formsy
              onValidSubmit={this.onFormSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              ref={form => (this.form = form)}
              className=""
              >
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <FormsyInputField
                    className="mb-8 w-full"
                    type="text"
                    name="name"
                    label="Name"
                    validations={{
                      minLength: 3,
                    }}
                    validationErrors={{
                      minLength: 'Please enter a name with at least 3 characters.',
                    }}
                    required
                    autocomplete="on"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormsyInputField
                    className="mb-8 w-full"
                    type="text"
                    name="email"
                    label="Email"
                    validations="isEmail"
                    validationErrors={{
                      isEmail: 'Please enter a valid email.',
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormsyInputField
                    className="w-full"
                    type="text"
                    multiline
                    rows={3}
                    rowsMax={6}
                    name="message"
                    label="Message (Optional)"
                    placeholder="What are some risk factors of infertility? What menstrual cycle symptoms should I discuss with my doctor?"
                  />
                </Grid>

                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classNames('w-full', classes.button)}
                    aria-label="Apply today"
                    disabled={loading || sent || !canSubmit}
                  >
                    Apply today
                    {loading && (
                        <CircularProgress size={24} className={classes.progress} />
                    )}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  {sent && <p className="success-message text-center">
                    Thank you for applying. A member of our team will contact you soon.
                  </p>}
{/*
                  {error && <p className="error-message text-center">
                    {error}
                  </p>} */}
                </Grid>
              </Grid>
            </Formsy>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

ContactCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContactCTA);
