import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import Formsy from 'formsy-react';

import FormsyInputField from './FormsyInputField';
import { contactRequest } from './contactRequest';

const styles = {
  button: {
    height: 60,
    textTransform: 'uppercase',
    letterSpacing: 3,
  },
  buttonContainer: {
    marginTop: 12,
  },
  progress: {
    marginLeft: 8,
  },
};

class TopCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      error: false,
      loading: false,
      canSubmit: false,
    };
  }

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false, error: null });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };


  onFormSubmit = model => {
    if (!this.state.sent) {
      const request = {
        ...model,
        subject: 'Thank you for subscribing',
        extra: { source: 'Marketing site - Subscribe' },
        template: 'SUBSCRIBE'
      };

      this.setState({ loading: true }, () => {
        return contactRequest(request)
        .then(
          () => {
            this.setState({ sent: true, loading: false, error: null });
          },
        ).catch(error => {
          console.debug(error);
          // We don't want to show user an error message
          // And temporary hack for 502 Bad Gateway response
          this.setState({
            error: 'An error occured. Please try again later.',
            sent: true,
            loading: false
          })
        });
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { sent, loading, canSubmit, error } = this.state;

    return (
      <Formsy
        onValidSubmit={this.onFormSubmit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
        ref={form => (this.form = form)}
        className=""
      >
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <FormsyInputField
            className="mb-8 w-full"
            type="text"
            name="name"
            label="Name"
            validations={{
              minLength: 3,
            }}
            validationErrors={{
              minLength: 'Please enter a name with at least 3 characters.',
            }}
            required
            autocomplete="on"
          />
        </Grid>

        <Grid item xs={6}>
          <FormsyInputField
            className="mb-8 w-full"
            type="text"
            name="email"
            label="Email"
            validations="isEmail"
            validationErrors={{
              isEmail: 'Please enter a valid email.',
            }}
            required
          />
        </Grid>

        <Grid item xs={12} className={classes.buttonContainer}>

          <Button
            type="submit"
            variant="contained"
            className={classNames('w-full', classes.button)}
            aria-label="Apply today"
            disabled={loading || sent || !canSubmit}
          >
            Subscribe Today
            {loading && (
                <CircularProgress size={24} className={classes.progress} />
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          {sent && <p className="success-message text-center">
          Thank you for subscribing.
          </p>}

          {/* {error && <p className="error-message text-center">
            {error}
          </p>} */}
        </Grid>

        <Grid item xs={12}>
          <p className="typography-p text-center" style={{ fontSize: 12 }}>
            We’ll send you helpful periodic news and feature updates; you will
            be among the first to receive an invitation to the pilot
            application and testing program.
          </p>
        </Grid>
      </Grid>
    </Formsy>
    );
  }
}

TopCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TopCTA);
