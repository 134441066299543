import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Grid, Button, Hidden } from '@material-ui/core';
import colors from '../mui_theme/colors';

import img from '../images/backgrounds/why-it-matters.jpg';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  title: {
    title: {
      [theme.breakpoints.up('md')]: {
        fontSize: 36,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
  },
  textMango: {
    fontFamily: 'NotoSerif',
    fontSize: 20,
    lineHeight: '1.5',
    color: colors.mango,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    },
  },
  leftSection: {
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px 140px',
    },
  },
  textContent: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 448,
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px 140px 40px',
    },
  },
  image: {
    float: 'right',
    marginLeft: 32,
    maxWidth: 'calc(100% - 32px)',
    maxHeight: 'auto',
    width: 'calc(100% - 32px)',
    height: 'auto',
    borderRadius: '20px 0 0',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: 148,
    },
  },
});

function WhyCTA(props) {
  const { classes } = props;
  return (
    <Grid container spacing={0} className={classes.root}>
      <a href="#why" name="why" className={'absolute -mt-12'} />
      <Hidden mdUp>
        <Grid item xs={12} md={6}>
          <h6
            className={classNames(
              'typography-header float-right',
              classes.subtitle
            )}
          >
            WHY IT MATTERS
          </h6>
          <img src={img} className={classes.image} alt="Why it matters" />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6} className={classes.leftSection}>
        <div className={classes.textContent}>
          <h3 className={classNames('typography-title mb-4', classes.title)}>
            Fertility health is often ignored until it matters.
          </h3>
          <p className="typography-p mb-4">
            Medical appointments and diagnostic testing can be inconvenient and
            expensive, but your fertility health should not be ignored.
          </p>
          <p className="typography-p  mb-8">
            Neglecting irregularities and signs of hormonal imbalance could lead
            to infertility and long-term chronic health issues that will
            negatively affect your quality of life.
          </p>

          <p className={classNames('mb-8', classes.textMango)}>
            MotiveBio is a personalized fertility health application with a
            convenient at-home fertility test kit. We help you get the
            information you need to understand your reproductive health and to
            know why and when to see your doctor.
          </p>

          {/* <Button
            type="button"
            variant="contained"
            aria-label="Learn More"
            className={classes.button}
            target="_blank"
            href="/learn/menstrual-cycle-data"
          >
            Learn More
          </Button> */}
        </div>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={6}>
          <h6
            className={classNames(
              'typography-header float-right',
              classes.subtitle
            )}
          >
            WHY IT MATTERS
          </h6>
          <img src={img} className={classes.image} alt="Why it matters" />
        </Grid>
      </Hidden>
    </Grid>
  );
}

WhyCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WhyCTA);
