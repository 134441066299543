import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import colors from '../mui_theme/colors';

import img1 from '../images/how-it-works/01-test.jpg';
import img2 from '../images/how-it-works/02-track.jpg';
import img3 from '../images/how-it-works/03-analyze.jpg';
import img4 from '../images/how-it-works/04-take-action.jpg';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.midBlue,
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px 114px',
    },
  },
  section: {
    padding: 26,
  },
  subtitle: {
    marginRight: 26,
    marginBottom: 26,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: 247,
    width: 248,
  },
  firstImage: {
    borderRadius: '20px 0 0',
  },
  lastImage: {
    borderRadius: '0 0 20px',
  },
  image: {
    flexShrink: 0,
    minHeight: '100%',
    maxWidth: 'none !important',
    height: '100%',
  },
  sectionHeader: {
    color: '#fff',
    fontFamily: 'Muli',
    fontSize: 28,
    fontWeight: 800,
    lineHeight: '1.29',
    marginBottom: 12,
    marginTop: 12,
  },
});

function HowCTA(props) {
  const { classes } = props;
  return (
    <Grid container spacing={0} className={classes.root}>
      <a
        href="#how-it-works"
        name="how-it-works"
        className={'absolute -mt-20'}
      />
      <Grid item xs={12}>
        <h6
          className={classNames(
            'typography-header float-right',
            classes.subtitle
          )}
        >
          How it works
        </h6>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} md={3} className={classNames(classes.section)}>
          <div className={classNames(classes.imgContainer, classes.firstImage)}>
            <img src={img1} className={classes.image} alt="Test" />
          </div>
          <h3 className={classes.sectionHeader}>Test</h3>
          <p className="typography-p color-white">
            {`Conveniently collect your sample at home, ship to the lab, and get your results <2 days.`}
          </p>
        </Grid>
        <Grid item xs={12} md={3} className={classes.section}>
          <div className={classes.imgContainer}>
            <img src={img2} className={classes.image} alt="Track" />
          </div>
          <h3 className={classes.sectionHeader}>Track</h3>
          <p className="typography-p color-white">
            Track your menstrual cycle health and trends.
          </p>
        </Grid>
        <Grid item xs={12} md={3} className={classes.section}>
          <div className={classes.imgContainer}>
            <img src={img3} className={classes.image} alt="Analyze" />
          </div>
          <h3 className={classes.sectionHeader}>Analyze</h3>
          <p className="typography-p color-white">
            We combine your personal health, body measurements, and laboratory
            findings into an easy to understand report.
          </p>
        </Grid>
        <Grid item xs={12} md={3} className={classNames(classes.section)}>
          <div className={classNames(classes.imgContainer, classes.lastImage)}>
            <img src={img4} className={classes.image} alt="Take Action" />
          </div>
          <h3 className={classes.sectionHeader}>Take Action</h3>
          <p className="typography-p color-white">
            Share with your healthcare provider. You and your doctor benefit
            from having a complete chart on your first visit.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

HowCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(HowCTA);
