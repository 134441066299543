import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Fab } from '@material-ui/core';
import classNames from 'classnames';

import Logo from '../images/logos/logo-blue.svg';
import EmailIcon from '../images/icons/0151-envelope.svg';
import MapIcon from '../images/icons/0386-map.svg';
import PhoneIcon from '../images/icons/0363-telephone.svg';
import FacebookIcon from '../images/icons/facebook.png';
import LinkedInIcon from '../images/icons/linkedin.png';
import TwitterIcon from '../images/icons/twitter.png';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import colors from '../mui_theme/colors';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: colors.white,
      padding: '32px 32px 0 32px',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#fff',
      padding: 0,
    },
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: '40px 40px',
    },
  },
  logo: {
    height: 40,
    width: 'auto',
  },
  contactSection: {
    padding: '40px 8px',
  },
  copyrightSection: {
    backgroundColor: colors.white,
    height: 40,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  copyrightSectionText: {
    fontFamily: 'Muli',
    fontSize: 12,
    fontWeight: 800,
    lineHeight: '1.58',
    color: colors.pinkishGrey,
  },
  icon: {
    width: 20,
    height: 'auto',
    marginRight: 4,
    marginBottom: -4,
  },
  paragraphText: {
    fontFamily: 'Muli',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '1.5',
    color: colors.pinkishGrey,
    textDecoration: 'none !important',
    display: 'block',
  },
  arrowButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowButton: {
    color: '#fff',
    backgroundColor: colors.pinkishGrey,
  },
});

const GOOGLE_MAPS_LINK =
  'https://www.google.pl/maps/place/445+W.+Medical+Center+Blvd,+Webster,+TX+77598,+Stany+Zjednoczone/@29.5409244,-95.1278863,17z/data=!3m1!4b1!4m5!3m4!1s0x86409c8d9a2418c3:0xf69dfb49fc289b9f!8m2!3d29.5409244!4d-95.1256976';

class BottomEncloser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        className={classNames('w-full absolute p-r p-l p-b', classes.root)}
      >
        <Grid item xs={12} md={4} className={classes.logoContainer}>
          <a href="/">
            <img src={Logo} alt="motiveBio" className={classes.logo} />
          </a>
        </Grid>

        <Grid item xs={12} md={3} className={classes.contactSection}>
          <h6 className={classNames('typography-header mb-4')}>Contact us</h6>

          <p className="">
            <a
              className={classNames('no-underline', classes.paragraphText)}
              href={GOOGLE_MAPS_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={MapIcon} alt="Location" className={classes.icon} />
              445 Medical Center Blvd
            </a>
          </p>

          <p className="mb-4" style={{ marginLeft: 24 }}>
            <a
              className={classNames('no-underline', classes.paragraphText)}
              href={GOOGLE_MAPS_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Webster, TX 77598
            </a>
          </p>
          <p
            className={classNames(classes.paragraphText, 'mb-4')}
            style={{ marginLeft: 24 }}
          >
            Office: +1.832.736.7527
          </p>
          <a
            href="mailto:info@motivebio.com"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <img src={EmailIcon} alt="Email" className={classes.icon} />
            <span style={{ display: 'inline-block'}} className={classNames('no-underline', classes.paragraphText)}>
              info@motivebio.com
            </span>
          </a>
        </Grid>
        <Grid item xs={12} md={2} className={classes.contactSection}>
          <h6 className={classNames('typography-header mb-4')}>Follow us</h6>

          <a
            href="https://www.facebook.com/MotiveBio-120410592682964/"
            className={classNames(classes.paragraphText, 'mb-4')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookIcon} alt="Facebook" className={classes.icon} />
            Facebook
          </a>

          <a
            href="https://twitter.com/motivebio"
            className={classNames(classes.paragraphText, 'mb-4')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterIcon} alt="Twitter" className={classes.icon} />
            Twitter
          </a>

          <a
            href="https://www.linkedin.com/company/motivebio"
            className={classNames(classes.paragraphText, 'mb-4')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedInIcon} alt="LinkedIn" className={classes.icon} />
            LinkedIn
          </a>
        </Grid>
        <Grid item xs={12} md={2} className={classes.contactSection}>
          <h6 className={classNames('typography-header mb-4')}>
            Legal information
          </h6>
          <p className="mb-4">
            <a
              className={classNames('no-underline', classes.paragraphText)}
              href="/privacy_policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p className="mb-4">
            <a
              className={classNames('no-underline', classes.paragraphText)}
              href="/cookie_policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>
          </p>

          <p className="mb-4">
            <a
              className={classNames('no-underline', classes.paragraphText)}
              href="/tos"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </p>
        </Grid>
        <Grid item xs={12} md={1} className={classes.arrowButtonContainer}>
          <Fab
            variant="extended"
            href="/#top"
            aria-label="Go To Top"
            className={classes.arrowButton}
          >
            <ArrowUpwardIcon fontSize="inherit" />
          </Fab>
        </Grid>

        <Grid
          item
          xs={12}
          className={classNames(
            classes.copyrightSection,
            classes.copyrightSectionText
          )}
        >
          <p>
            <span>©2019 Motive Biosciences All Rights Reserved</span>
          </p>
        </Grid>
      </Grid>
    );
  }
}

BottomEncloser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BottomEncloser);
