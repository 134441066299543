import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import DropIcon from '../images/icons/0054-drop2.svg';
import MicroscopeIcon from '../images/icons/0498-microscope.svg';
import KitImage from '../images/backgrounds/motivebio-kit-box.jpg';

import colors from '../mui_theme/colors';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 32,
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: '100px 140px 60px',
    },
  },
  violet: {
    backgroundColor: colors.purply,
    borderRadius: '20px 0 0',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: -70,
      height: 'calc(100% + 70px)',
    },
  },
  blue: {
    backgroundColor: colors.midBlue,
    borderRadius: '0 0 20px',
  },
  subtitle: {
    marginBottom: 46,
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: 52,
    },
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      height: 32,
      minHeight: 32,
    },
    [theme.breakpoints.up('md')]: {
      height: 52,
      minHeight: 52,
    },
  },
  disclaimer: {
    maxWidth: 748,
    fontFamily: 'Muli',
    fontSize: 12,
    lineHeight: '1.5',
    textAlign: 'center',
    color: colors.pinkishGrey,
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  kitImage: {
    width: '100%',
    height: 'auto',
    alignSelf: 'center',
  },
  kitImageContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 250,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
});

function KitCTA(props) {
  const { classes } = props;
  return (
    <Grid container spacing={8} className={classes.root}>
      <a
        href="#our-solution"
        name="our-solution"
        className={'absolute -mt-20'}
      />
      <Grid item xs={12}>
        <h6
          className={classNames(
            'typography-header float-right',
            classes.subtitle
          )}
        >
          At Home Test Kit
        </h6>
      </Grid>
      <Grid item xs={12} md={5}>
        <div className={classNames(classes.violet, classes.section)}>
          <p className="typography-p color-white  mt-2">
            We use a few drops of blood from a fingerstick on a uniquely
            serialized filter paper card to measure key reproductive hormones.
          </p>
          <h3
            className={classNames(
              'typography-title color-white mt-2',
              classes.title
            )}
          >
            Give a blood sample.
          </h3>
          <img
            src={DropIcon}
            className={classes.image}
            alt="Give a blood sample."
          />
        </div>
      </Grid>

      <Grid item xs={12} md={7}>
        <div className={classNames(classes.blue, classes.section)}>
          <p className="typography-p color-white mt-2">
            Your sample is tested by our lab within 48 hours. When used with the
            MotiveBio fertility health application, we can combine your
            menstrual cycle data and hormone measurements into a personalized
            fertility health assessment. Your personal data and laboratory
            results are encrypted and only obtainable by you.
          </p>
          <h3
            className={classNames(
              'typography-title color-white mt-2',
              classes.title
            )}
          >
            Get results from our CLIA Lab.
          </h3>
          <img
            src={MicroscopeIcon}
            className={classes.image}
            alt="Get results from our CLIA Lab."
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <p className={classes.disclaimer}>
          The materials published by MotiveBio are for educational and general
          information purposes only, and are not intended as a diagnosis,
          treatment or as a substitute for professional medical advice. Please
          consult a physician or other health care professional for your
          specific health care concerns.
        </p>
      </Grid>
    </Grid>
  );
}

KitCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KitCTA);
