import React from 'react';

import withRoot from '../utils/withRoot';
import WhyCTA from '../components/WhyCTA';
import HowCTA from '../components/HowCTA';
import KitCTA from '../components/KitCTA';
import JoinTodayForm from '../components/JoinTodayForm';
import ContactCTA from '../components/ContactCTA';
import SEO from '../components/Seo';
import Page from '../components/Page';

import { Grid } from '@material-ui/core';

// Example implementation MUI-Gatsby: https://github.com/bluepeter/gatsby-material-ui-business-starter/blob/master/src/pages/index.js

const IndexPage = () => (
  <Page>
    <SEO title="Take control of your fertility health.">
    </SEO>

    <Grid container spacing={0}>
      <JoinTodayForm />
      <WhyCTA />
      <HowCTA />
      <KitCTA />
      <ContactCTA />
    </Grid>
  </Page>
);

export default withRoot(IndexPage);
