module.exports = {
  white: '#f4f4f4',
  whiteDark10: '#dcdcdc',
  mango: '#e86d2e',
  mangoDark10: '#e68320',
  purply: '#713cc2',
  purplyDark10: '#6636af',
  pinkishGrey: '#9A9292',
  pinkishGreyDark10: '#b4abab',
  cobalt: '#1e317a',
  midBlue04: '#a5c3dc',
  midBlue06: '#79a4c8',
  midBlue08: '#4c84b6',
  midBlue: '#1f67a4',
  greyBorder: '#dedede',
  greyShadow: '#e9e9e9',
  lightGreen: '#8bc34a',
  yellow: '#fdd835',
  red: '#e64a19',
};
