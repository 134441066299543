const colors = require('./colors');
module.exports = {
  palette: {
    type: 'light',
    primary: {
      light: colors.purply024,
      main: colors.purply,
      dark: '#5d31a0',
      contrastText: '#ffffff',
    },
    secondary: {
      light: colors.pinkishGrey,
      main: '#273036',
      dark: '#000710',
      contrastText: '#ffffff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: ['Muli', 'NotoSerif'],
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'Muli',
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: 'normal',
        minHeight: 40,
        textTransform: 'none',
        color: colors.pinkishGrey,
        lineHeight: '1.5',
      },
      contained: {
        backgroundColor: colors.purply,
        color: 'white',
        '&:hover': {
          backgroundColor: `${colors.purplyDark10} !important`,
          color: '#fff !important',
        },
        fontWeight: 800,
      },
      outlined: {
        border: `2px solid ${colors.pinkishGrey}`,
        color: colors.pinkishGrey,
      },
    },
    MuiInputLabel: {
      shrink: {
        fontSize: 20,
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: `${colors.white} !important`,
        color: colors.pinkishGrey,
      },
    },
  },
};
