import React from 'react';

import TopAppBar from './TopAppBar';
import BottomEncloser from './BottomEncloser';

import 'typeface-muli';
import 'typeface-noto-serif';
import 'typeface-roboto';
import '../css/global.css';

class Page extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <TopAppBar />
        {children}
        <BottomEncloser />
      </>
    );
  }
}

export default Page;
