import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Button,
  Hidden,
  SwipeableDrawer,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
} from '@material-ui/core';

import Logo from '../images/logos/logo-blue.svg';
import colors from '../mui_theme/colors';
import ArrowIcon from '../images/icons/0837-arrow-right.svg';

const styles = {
  toolbarDesktop: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItemDesktop: {
    marginLeft: 8,
  },
  signUpButton: {
    width: 180,
    height: 50,
  },
  menuIconMobile: {
    color: colors.pinkishGrey,
  },
  arrowIcon: {
    marginLeft: 4,
  },
};

class TopAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
    };
  }

  toggleDrawer = open => () => {
    this.setState({
      drawer: open,
    });
  };

  render() {
    const { classes } = this.props;
    const navLinks = [
      { text: 'Why it Matters', href: '/#why' },
      { text: 'How it Works', href: '/#how-it-works' },
      { text: 'At Home Test Kit', href: '/#our-solution' },
      { text: 'Contact', href: '/#contact' },
    ];
    const sideList = (
      <List>
        {navLinks.map((item, index) => (
          <ListItem key={item.text}>
            <Button href={item.href}> {item.text} </Button>
          </ListItem>
        ))}
        <ListItem>
          <Button
            type="button"
            variant="contained"
            className={classNames(classes.signUpButton)}
            aria-label="Sign up"
            href="/#contact"
          >
            Sign Up <img src={ArrowIcon} className={classes.arrowIcon} />
          </Button>
        </ListItem>
      </List>
    );

    return (
      <React.Fragment>
        <AppBar position="fixed" color="default">
          <Hidden smDown>
            <Toolbar className={classes.toolbarDesktop}>
              <a href="/">
                <img src={Logo} alt="motiveBio" />
              </a>

              <div>
                {navLinks.map((item, index) => (
                  <Button
                    href={item.href}
                    key={item.href}
                    className={classes.menuItemDesktop}
                  >
                    {item.text}
                  </Button>
                ))}
                <Button
                  type="button"
                  variant="contained"
                  className={classNames(
                    classes.menuItemDesktop,
                    classes.signUpButton
                  )}
                  aria-label="Sign up"
                  href="/#contact"
                >
                  Sign Up <img src={ArrowIcon} className={classes.arrowIcon} />
                </Button>
              </div>
            </Toolbar>
          </Hidden>
          <Hidden mdUp>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer(true)}
                className={classes.menuIconMobile}
              >
                <MenuIcon />
              </IconButton>
              <img src={Logo} alt="motiveBio" />
            </Toolbar>
          </Hidden>
        </AppBar>
        <SwipeableDrawer
          open={this.state.drawer}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
}

TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TopAppBar);
