import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import {
  FormHelperText,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import pick from 'lodash.pick';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class FormsyInputField extends Component {
  state = {
    showPassword: false,
  }

  changeValue = event => {
    this.props.setValue(event.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  handleClickShowPassword = () => {
    const showPassword = !this.state.showPassword;
    this.setState({ showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  componentDidUpdate() {
    if (this.props.getValue() === undefined && this.props.defaultValue) {
      this.props.setValue(this.props.defaultValue);
    }
  }

  render() {
    const importedProps = pick(this.props, [
      'required',
      'id',
      'name',
      'inputProps',
      'endAdornment',
      'disabled',
      'readOnly',
      'multiline',
      'rows',
      'rowsMax',
      'autocomplete',
      'placeholder',
    ]);

    const {enableShowPassword} = this.props || false;
    const inputType = enableShowPassword ?
    this.state.showPassword ? 'text' : 'password'
      : this.props.type;

    const errorMessage = this.props.getErrorMessage();
    const value =
      this.props.getValue() !== undefined
        ? this.props.getValue()
        : this.props.defaultValue || '';
    const showError = Boolean(errorMessage) && !this.props.isPristine();

    return (
      <FormControl className={this.props.className} error={showError}>
        <InputLabel htmlFor={this.props.name}>{this.props.label}</InputLabel>
        <Input
          id={this.props.name}
          value={value}
          onChange={this.changeValue}
          type={inputType}
          {...importedProps}
          endAdornment={
            enableShowPassword ? (<InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            ) : this.props.endAdornment}
        />
        {showError && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  }
}

export default withFormsy(FormsyInputField);
