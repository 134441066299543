import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import TopCTA from './TopCTA';
import colors from '../mui_theme/colors';

import backgroundImg from '../images/backgrounds/header.jpg';

const styles = theme => ({
  root: {
    height: '100%',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      maxHeight: 400,
      borderRadius: '0 0 0',
    },
    [theme.breakpoints.up('md')]: {
      height: 820,
      maxHeight: 'auto',
      borderRadius: '0 0 25px',
    },
  },
  img: {
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 400,
      maxWidth: '100%',
      height: 'auto',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '100%',
      minHeight: '100%',
      maxWidth: 'none',
      height: '100%',
      maxHeight: 'auto',
    },
  },
  form: {
    backgroundColor: colors.white,
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
    [theme.breakpoints.up('md')]: {
      padding: 104,
    },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  formContent: {
    width: '100%',
  },
});

class JoinTodayForm extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className="mt-12">
        <a href="#top" name="top" className={'absolute -mt-24'} />
        <Grid item xs={12} md={6} className={classes.imgContainer}>
          <img src={backgroundImg} className={classes.img} />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.form}>
            <div className={classes.formContent}>
              <p className="typography-header mb-8">JOIN TODAY</p>
              <h1
                className={classNames('typography-title mb-8', classes.title)}
              >
                Take control of your fertility health.
              </h1>
              <p className="typography-p mb-2">
                Introducing your personalized fertility health test. Convenient
                and affordable testing and reporting made just for you.
              </p>
              <p className="typography-p mb-8">
                We’ll help you learn about your reproductive health and know why
                and when to see your doctor.
              </p>
              <TopCTA />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

JoinTodayForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(JoinTodayForm);
